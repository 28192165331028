import React, { Component } from "react";  
import { Container , Row , Col  } from 'react-bootstrap';   
import Global from "../Global.json";

export class events extends Component { 
    constructor(props) {
        super(props); 
        this.state = {  
          value : 0,
          user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
          guest_id: localStorage.getItem("guest_id") ? localStorage.getItem("guest_id"): 0,
          Events : [],
          isPageLoad : false,
          event_id: props.match.params.event_id,
        } 
    }
  
    componentWillMount () {
        fetch(Global.api_link + 'getEvents', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token, 
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                   this.setState({
                     Events : result.data
                   })
                }
            )
    }

    descriptionProduct(text) {
        return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
    }

    createMarkup(text) {
        return { __html: text };
    }

    
    render() { 
        if (this.state.isPageLoad) {

            return <div> 
                <div className="Loader">
                    <img alt="JaDough" src="/img/giphy.gif" />
                </div>
            </div>;
        }else {  
        return ( 
         <div>
             <div className="BodyImage">
                <Container>
                    <Row className="Desserts Events"> {/** Desserts */}
                    <Col md="12" sm="12" xs="12"> 

                    {this.state.event_id == 1 ?
                    <div>
                        
                         <h1>Spooktacular Party </h1>
                        <br/>
                        <p>Don’t miss out on our Customized Menu, order from JaDough, promising to be Spectacular! </p> <br/>
                        <img alt="Menu" src="/img/SpooktacularMenu.jpg" width="100%" /> <br/>  <br/> <br/>
                        <p>
                        24th October 5.30pm – 8.30pm – JaDough will be Catering for MyGym Spooktacular Fancy Dress Party
                        25th October 5.30pm – 8.30pm – JaDough will be Catering for MyGym Spooktacular Fancy Dress Party
                        </p> 
                    </div>
                    : null}
                       
                    {this.state.event_id == 2 ?
                    <div>
                       
                        <h1>Qatar National Day </h1>
                        <br/>
                        <p>Customized National Day treat and lots more, celebrate Qatar with JaDough</p>
                        <strong>Coming Soon!</strong>
                    </div>
                    : null}

                    {this.state.event_id == 3 ?
                    <div>
                       
                        <h1>Winter Festival  </h1>
                        <br/>
                        <p>Don’t miss out on our Winter Festival Menu, ‘tis the season to be Jolly! 
                        Our customized Menu will wow your guests, order from JaDough and spend this special occasion with friends and family, not in the kitchen!
                        </p>
                        <iframe src="https://jadough.com/festival.pdf" style={{width:"100%",height:800}} frameborder="0"></iframe>
                        
                    </div>
                    : null}

                    {this.state.event_id == 4 ?
                    <div>
                       
                        <h1>New Year’s Day</h1>
                        <br/>
                        <p>Don’t miss out on our New Year’s Menu, buffet styled or elegant Canapes, ring in the New Year with style.
                        </p>
                        <iframe src="https://jadough.com/newyear.pdf" style={{width:"100%",height:800}} frameborder="0"></iframe>
                    </div>
                    : null}


                    </Col> 
                    </Row>
                </Container>
             </div>
         </div>   
        );
    }
}
}

export default events;

