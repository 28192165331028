import React, { Component } from "react";  
import { Container , Row , Col  } from 'react-bootstrap';  
import Masonry from 'react-masonry-component';
import Global from '../Global.json';


const masonryOptions = {
    transitionDuration: 0,
    columnWidth: 0, 
    
};
 
const imagesLoadedOptions = { background: '.my-bg-image-el' }
const style = { 
    listStyle: "none",
    display: "-webkit-inline-box"
};
 

export class GalleryPage extends Component { 
    constructor(props) {
        super(props); 
        this.state = {  
          value : 0,
          IMAGES: [],
          isPageLoad: false,
          user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
          guest_id: localStorage.getItem("guest_id") ? localStorage.getItem("guest_id"): 0,
          viewerIsOpen:false,
          currentImage:0
        } 

        
    }
 
    componentWillMount() {
        this.getProductGallery()
    }
    getProductGallery() {
        fetch(Global.api_link + 'getGalleryPage', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                    this.setState({
                        IMAGES: result.data,
                    });
                }
            );
    }
    


    render() {
  
        const childElements = this.state.IMAGES.map(function(element){
            return (
                 <li className="image-element-class">
                     <img src={Global.image_link + element.image} />
                 </li>
             );
         });
     
        return (
         <div>
             <div className="BodyImage">
                <Container>
                    <Row className="Desserts GalleryPage"> {/** Desserts */}
                    <Col md="12" sm="12" xs="12">
                    <br/>
                        <h1>JaDough Gallery </h1>
                        <br/>
                        <Masonry
                            className={'my-gallery-class'} // default ''
                            elementType={'div'} // default 'div'
                     
                        >
                            {childElements}
                        </Masonry>
                        
                    </Col> 
                    </Row>
                </Container>
             </div>
         </div>   
        );
    }
}

export default GalleryPage;

