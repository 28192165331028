import React, { Component } from "react";  
import { Container , Row , Col ,Form , Button } from 'react-bootstrap';    
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Global from '../Global.json'; 

export class Contact extends Component { 
    constructor(props) {
        super(props); 
        this.state = {  
            showingInfoWindow: true,
            activeMarker: {},
            isPageLoad :true,
            selectedPlace: {}, 
            Settings : [],
            user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
        } 
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
    }
  
  
componentWillMount() {
this.getSettings()
}

SpecialOrder() { 
    if( this.state.Name  && this.state.Phone && this.state.Address && this.state.EmailSignUp && this.state.Details   != null){
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
        if(reg.test(this.state.EmailSignUp) === false) {
          
          alert("Enter Valid Email Syntax")
          this.setState({
            isPageLoad : false,
        });
        }else{
        fetch(Global.api_link + 'SpecialOrder', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token : Global.token,
                email: this.state.EmailSignUp,
                name: this.state.Name,
                phone: this.state.Phone,
                details: this.state.Details,
                user_id: this.state.user_id,
                address: this.state.Address
            }),
          }).then((response) => response.json())
            .then(
                (result) => {    
                    this.setState({
                        isPageLoad : false,
                    });

                    alert("Your special order has been submitted")
                    window.location.reload()
                 } 
        )
                }}
    else{
        alert ("All Fields Required ")
        this.setState({
            isPageLoad : false,
        });
      }  
}


onMarkerClick = (props, marker, e) => {

    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      windowProps: props
    });
  }

onMapClick = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  
  }

  getSettings() { 
    fetch(Global.api_link + 'getSettings', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token : Global.token, 
          lang : "english"
        }),
      }).then((response) => response.json())
        .then(
            (result) => {   
               this.setState({
                   Settings :result.data,
                   isPageLoad : false
               }) 
             }
             
        );  
  }


  
descriptionProduct(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }

  createMarkup(text) {
    return { __html: text };
  }

  
    render() {
        if (this.state.isPageLoad) {

            return <div> 
                <div className="Loader">
                    <img alt="JaDough" src="img/giphy.gif" />
                </div>
            </div>;
        }else {  
        return (
         <div>
             <div className="BodyImage">
                <Container>
                    <Row className="DessertsDet"> {/** Desserts */}
                        <Col md="12" sm="12" xs="12" className="MapContact">
                            <Col  className="no_pad">
                                <div className="Contact">
                                    <h3>Contact Us</h3>
                                    <ul>
                                    <li> {this.descriptionProduct(this.state.Settings.location)}</li>
                                    <li> {this.descriptionProduct(this.state.Settings.phone)}</li>
                                    <li> {this.descriptionProduct(this.state.Settings.email)}</li>                               
                                    </ul>

                                    <h3>Follow Us</h3>
                                    <div className="Social">
                                        <ol>
                                            <li><a target="_blank" href={this.state.Settings.facebook}><i className="fab fa-facebook"></i></a></li>
                                            <li><a target="_blank" href={this.state.Settings.twitter}><i className="fab fa-twitter"></i></a></li>
                                            <li><a target="_blank" href={this.state.Settings.instagram}><i className="fab fa-instagram"></i></a></li>
                                        </ol>
                                    </div>
                                    
                                </div>                             
                            </Col>
 
                        </Col> 
                    </Row>
                    {/** 
                    <Row>
                        <Col md="12" sm="12" xs="12" className="SpecialOrder">
                            <h1> Special Order</h1>
                      
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="name" name="name" onChange={(name) => this.setState({Name : name.target.value})}/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" placeholder="phone" name="phone" onChange={(phone) => this.setState({Phone : phone.target.value})} />
                                </Form.Group>
                            </Form.Row>
 

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="email"  name="email" onChange={(email) => this.setState({EmailSignUp : email.target.value})} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Address </Form.Label>
                                <Form.Control type="text" placeholder="address " name="address"  onChange={(address) => this.setState({Address : address.target.value})}/>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Your order details</Form.Label>
                                <Form.Control as="textarea" rows="3"  placeholder="order details" name="msg" onChange={(msg) => this.setState({Details : msg.target.value})} />
                                </Form.Group> 
                            </Form.Row>

                            <Button variant="primary" type="submit" variant="outline-secondary" onClick={() => {
                                        this.setState(
                                            {
                                            isPageLoad: true, 
                                            },
                                            () => {
                                            this.SpecialOrder();
                                            }
                                        );
                                        }}>
                                Submit
                            </Button>
                        </Col>
                    </Row>*/}
                </Container>
             </div>
         </div>   
        );
    }
}}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyCYu1AMEWJXx4EDXm4VdagRPCC2jHh85Qw'),
})(Contact)