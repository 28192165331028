import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Global from "../Global.json";

export class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      Cart: [],
      Profile: [],
      Total: [],
      Payment: 1,
      isPageLoad: true,
      locations: [],
      CartID: props.match.params.CartID,
      cost: "0",
      area: localStorage.getItem("area")
        ? localStorage.getItem("area")
        : "Choose Your Area",
      guest_id: localStorage.getItem("guest_id")
        ? localStorage.getItem("guest_id")
        : 0
    };
  }

  decrease = () => {
    if (this.state.value != "0") {
      this.setState({ value: this.state.value - 1 });
    }
  };

  increase = () => {
    this.setState({ value: this.state.value + 1 });
  };

  componentWillMount() {
    this.getUserCart();
    this.getUserProfile();
    this.getLocations();

    if (this.state.guest_id == 0) {
      //console.log(this.state.guest_id);
      fetch(Global.api_link + "generateGuestID", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token: Global.token
        })
      })
        .then(response => response.json())
        .then(result => {
          // console.log(result);
          localStorage.setItem("guest_id", result.data);
        });
    }
  }

  getUserCart() {
    if (this.state.CartID == 1) {
      fetch(Global.api_link + "getUserCart", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          user_id:
            this.state.user_id != 0 ? this.state.user_id : this.state.guest_id,
          lang: "english"
        })
      })
        .then(response => response.json())
        .then(result => {
          this.setState({
            isPageLoad: false,
            Total: result.total,
            delivery: result.total * 0.5,
            VAT: result.total * 0.2,
            discount_amount: 0
          });
        });
    } else {
      fetch(Global.api_link + "getUserCartLater", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          user_id:
            this.state.user_id != 0 ? this.state.user_id : this.state.guest_id,
          lang: "english"
        })
      })
        .then(response => response.json())
        .then(result => {
          this.setState({
            isPageLoad: false,
            Total: result.total,
            delivery: result.total * 0.5,
            VAT: result.total * 0.2,
            discount_amount: 0
          });
        });
    }
  }

  getUserProfile() {
    if (this.state.user_id != 0) {
      fetch(Global.api_link + "getUserProfile", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          user_id:
            this.state.user_id != 0 ? this.state.user_id : this.state.guest_id
        })
      })
        .then(response => response.json())
        .then(result => {
          this.setState({
            Name: result.data.name,
            Phone: result.data.phone,
            Location: result.data.address,
            EmailSignUp: result.data.email,
            Name: result.data.name
          });
        });
    }
  }

  createUserOrder() {
    if (this.state.CartID == 1) {
      if (this.state.cost != 0) {
        if (this.state.Total < this.state.minimunCost) {
          alert(
            "Your order haven't achieved the minimum amount ,The minimum order amount for your area is " +
              this.state.minimunCost +
              " QAR"
          );
          this.setState({
            isPageLoad: false
          });
        } else {
          let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if (reg.test(this.state.EmailSignUp) === false) {
            alert("Enter Valid Email Syntax");
            this.setState({
              isPageLoad: false
            });
          } else {
            fetch(Global.api_link + "createUserOrder", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                token: Global.token,
                phone: this.state.Phone,
                location: this.state.Location,
                address: this.state.area,
                email: this.state.EmailSignUp,
                payment_method: this.state.Payment,
                user_name: this.state.Name,
                product_amount: this.state.Total,
                shipping_amount: this.state.cost,
                vat_amount: 0,
                user_id:
                  this.state.user_id !== 0
                    ? this.state.user_id
                    : this.state.guest_id,
                discount_amount: this.state.discount_amount
                  ? this.state.discount_amount
                  : 0,
                total_amount:
                  parseFloat(this.state.Total) + parseFloat(this.state.cost)
              })
            })
              .then(response => response.json())
              .then(result => {
                /* 
              this.setState({
                  isPageLoad : false
              })*/

                if (this.state.Payment == 1) {
                  let userID =
                    this.state.user_id !== 0
                      ? this.state.user_id
                      : this.state.guest_id;
                  window.location.href =
                    "https://merwas.net/jadough/Webapi/CompleteOrder?token=43abc9a4f620c34d3bbb863dd6c1e472" +
                    "&user_id=" +
                    userID +
                    "&cartID=1&order_id=" +
                    result.data;
                } else {
                  let userID =
                    this.state.user_id !== 0
                      ? this.state.user_id
                      : this.state.guest_id;
                  window.location.href =
                    "https://merwas.net/jadough/Webapi/creditCardLink?token=43abc9a4f620c34d3bbb863dd6c1e472" +
                    "&user_id=" +
                    userID +
                    "&hash=" +
                    result.data;
                }
              });
          }
        }
      } else {
        alert("Plese select area first");
        this.setState({
          isPageLoad: false
        });
      }
    } else {
      if (this.state.cost != 0) {
        if (this.state.Total < this.state.minimunCost) {
          alert(
            "Your order haven't achieved the minimum amount ,The minimum order amount for your area is " +
              this.state.minimunCost +
              " QAR"
          );
          this.setState({
            isPageLoad: false
          });
        } else {
          let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if (reg.test(this.state.EmailSignUp) === false) {
            alert("Enter Valid Email Syntax");
            this.setState({
              isPageLoad: false
            });
          } else {
            fetch(Global.api_link + "createUserOrderLater", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                token: Global.token,
                phone: this.state.Phone,
                location: this.state.Location,
                address: this.state.area,
                email: this.state.EmailSignUp,
                payment_method: this.state.Payment,
                user_name: this.state.Name,
                product_amount: this.state.Total,
                shipping_amount: this.state.cost,
                vat_amount: 0,
                user_id:
                  this.state.user_id !== 0
                    ? this.state.user_id
                    : this.state.guest_id,
                discount_amount: this.state.discount_amount
                  ? this.state.discount_amount
                  : 0,
                total_amount:
                  parseFloat(this.state.Total) + parseFloat(this.state.cost)
              })
            })
              .then(response => response.json())
              .then(result => {
                /* 
                  this.setState({
                      isPageLoad : false
                  })*/

                if (this.state.Payment == 1) {
                  let userID =
                    this.state.user_id !== 0
                      ? this.state.user_id
                      : this.state.guest_id;
                  window.location.href =
                    "https://merwas.net/jadough/Webapi/CompleteOrder?token=43abc9a4f620c34d3bbb863dd6c1e472" +
                    "&user_id=" +
                    userID +
                    "&cartID=3&order_id=" +
                    result.data;
                } else {
                  let userID =
                    this.state.user_id !== 0
                      ? this.state.user_id
                      : this.state.guest_id;
                  window.location.href =
                    "https://merwas.net/jadough/Webapi/creditCardLink?token=43abc9a4f620c34d3bbb863dd6c1e472" +
                    "&user_id=" +
                    userID +
                    "&hash=" +
                    result.data;
                }
              });
          }
        }
      } else {
        alert("Plese select area first");
        this.setState({
          isPageLoad: false
        });
      }
    }
  }

  getLocations() {
    fetch(Global.api_link + "getCheckoutLocations", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: "english"
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          locations: result.data,
          isPageLoad: false
        });

        this.getcityCost();
      });
  }

  getcityCost() {
    console.log(this.state.area);
    fetch(Global.api_link + "getcityCost", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        loc_name: this.state.area
      })
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.data) {
          this.setState({
            cost: result.data.delivery_cost,
            minimunCost: result.data.minimum_cost,
            CostMesg: result.data.msg_en,
            isPageLoad: false
          });
        } else {
          this.setState({
            isPageLoad: false
          });
        }
      });
  }

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="Loader">
            <img alt="JaDough" src="/img/giphy.gif" />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="BodyImage">
            <Container>
              <Row className="Desserts">
                {" "}
                {/** Desserts */}
                <Col md="12" sm="12" xs="12">
                  <h1>Checkout</h1>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="name"
                        name="name"
                        value={this.state.user_id != 0 ? this.state.Name : null}
                        onChange={name =>
                          this.setState({ Name: name.target.value })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="phone"
                        name="phone"
                        value={this.state.Phone}
                        onChange={phone =>
                          this.setState({ Phone: phone.target.value })
                        }
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="email"
                        name="email"
                        value={this.state.EmailSignUp}
                        onChange={email =>
                          this.setState({ EmailSignUp: email.target.value })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Address </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="location"
                        name="location"
                        value={this.state.Location}
                        onChange={location =>
                          this.setState({ Location: location.target.value })
                        }
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
                      <Form.Label>Area</Form.Label>
                      <Form.Control
                        as="select"
                        name="area"
                        onChange={area => {
                          this.setState(
                            {
                              isPageLoad: true,
                              area: area.target.value
                            },
                            () => {
                              this.getcityCost();
                            }
                          );
                        }}
                      >
                        <option value="-1">Choose Area</option>
                        {this.state.locations.map(loc => {
                          return (
                            <option
                              value={loc.name}
                              selected={
                                this.state.area == loc.name ? "selected" : null
                              }
                            >
                              {loc.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
                      <Form.Label>Payment Methods</Form.Label>
                      <Form.Control
                        as="select"
                        name="payment"
                        onChange={payment =>
                          this.setState({ Payment: payment.target.value })
                        }
                      >
                        <option value="0" disabled>
                          Choose Payment Method
                        </option>
                        <option value="1">Cash On Delivery</option>
                        <option value="2">Credit Card</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
                      <br />
                      <h3>
                        Order Amount : <span>QR {this.state.Total}</span>
                      </h3>{" "}
                      <br />
                      <h3>
                        Delivery Fees :{" "}
                        <span>
                          {this.state.cost ? "QR " + this.state.cost : 0}{" "}
                        </span>
                      </h3>{" "}
                      <br />
                      <h3>
                        VAT :{" "}
                        <span>
                          QR {parseFloat(this.state.VAT).toFixed(1) * 0}
                        </span>
                      </h3>{" "}
                      <br />
                      <h3>
                        Total Amount :{" "}
                        <span>
                          QR{" "}
                          {(
                            parseFloat(this.state.Total) +
                            parseFloat(this.state.cost)
                          ).toFixed(1)}
                        </span>
                      </h3>
                    </Form.Group>
                  </Form.Row>

                  {this.state.CostMesg ? (
                    <h4 className="CostMsg">
                      {this.state.CartID == 1
                        ? this.state.CostMesg
                        : "Your order will be delivered within 3 days"}
                    </h4>
                  ) : null}
                  {this.state.Total < this.state.minimunCost ? (
                    <h4 className="CostMsgError">
                      The minimum order for your area is : QR{" "}
                      {this.state.minimunCost}{" "}
                    </h4>
                  ) : null}

                  <Col
                    md="12"
                    sm="12"
                    xs="12"
                    className="OrderNOwContainer CheckoutButt"
                  >
                    <a
                      className="btn btn-primary OrderNOwBack"
                      onClick={() => this.handleBack()}
                    >
                      Continue Shopping
                    </a>

                    <a
                      className="OrderNOw"
                      onClick={() => {
                        this.setState(
                          {
                            isPageLoad: true
                          },
                          () => {
                            this.createUserOrder();
                          }
                        );
                      }}
                    >
                      Confirm
                    </a>
                  </Col>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }
}
export default Checkout;
