import React, { Component } from "react";  
import { Container , Row , Col ,Form , Button } from 'react-bootstrap';    

import Global from '../Global.json'; 

export class Register extends Component { 
    constructor(props) {
        super(props); 
        this.state = {  
            user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
            locations : [],
            area : localStorage.getItem('area') ? localStorage.getItem('area') : "Qatar",
        } 
    }
  
    componentWillMount() {
        this.getLocations () 
    }
    SignUp () {
        if( this.state.Name  && this.state.Phone && this.state.City && this.state.Address && this.state.EmailSignUp && this.state.Password   != null){
            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
            if(reg.test(this.state.EmailSignUp) === false) {
              
              alert("Enter Valid Email Syntax")
            }else{
            fetch(Global.api_link + 'userSignup', {
              method: 'POST',
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                token : Global.token,
                email:this.state.EmailSignUp,
                password:this.state.Password,
                name:this.state.Name,
                phone: this.state.Phone,
                facebook_id:this.state.Facebook ? this.state.Facebook : 0,
                google_id:this.state.Google ? this.state.Google : 0,
                city:this.state.City,
                address :this.state.Address
              }),
            }).then((response) => response.json())
              .then(
                  (result) => {   
                     if(result.status == 1) {
                      localStorage.setItem('user_id' , result.data );
                      window.location.href = "/" 
                     }else if(result.status == 2) {
                       alert("Email Found")
                     }
                     console.log(this.state.user_id)
                   }
                   
              ); 
          }
        }else{
          alert ("All Fields Required ")
        }
      }
        
      getLocations () { 
        fetch(Global.api_link + 'getMainLocations', {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              token: Global.token,
              lang : "english"
          }),
      }).then((response) => response.json())
          .then(
              (result) => {
                  this.setState({
                      locations: result.data,
                      isPageLoad : false
                  }); 
              }
          );
    
      }
      
    render() {
         
        return (
         <div>
             <div className="BodyImage">
                <Container>
                    <Row>
                        <Col md="12" sm="12" xs="12" className="SpecialOrder Signup">
                            <h1> Create your account </h1>
                            
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="name"  name="name" onChange={(name) => this.setState({Name : name.target.value})} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" placeholder="phone" name="phone" onChange={(phone) => this.setState({Phone : phone.target.value})} />
                                </Form.Group>
                            </Form.Row>
 

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="email" name="email" onChange={(email) => this.setState({EmailSignUp : email.target.value})} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Password </Form.Label>
                                <Form.Control type="password" placeholder="password"  name="password" onChange={(password) => this.setState({Password : password.target.value})} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                       
                                <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
                                <Form.Label>Area</Form.Label>
                                <Form.Control as="select" name="area" onChange={(area) => this.setState({City : area.target.value})}>
                                    <option value="0" disabled>Choose Area</option>
                                    {this.state.locations.map(loc => {
                                        return(
                                            <option  value={loc.name} selected= {this.state.area == loc.name ? 'selected' : null}>{loc.name}</option>
                                        )
                                    })}
                                     
                                </Form.Control>
                            </Form.Group> 

                                <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Address </Form.Label>
                                <Form.Control type="text" placeholder="address" name="address" onChange={(address) => this.setState({Address : address.target.value})} />
                                </Form.Group>
                            </Form.Row>
 

                            <Button variant="primary" type="submit" onClick={() => this.SignUp()}>
                                Register
                            </Button> 
                        </Col>
                    </Row>
                </Container>
             </div>
         </div>   
        );
    }
}

export default Register