import React, { Component } from "react";  
import { Container , Row , Col  } from 'react-bootstrap';  

export class SuccessCategring extends Component { 
    constructor(props) {
        super(props); 
        this.state = {  
          value : 0,
          user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
          guest_id: localStorage.getItem("guest_id") ? localStorage.getItem("guest_id"): 0,
         
        } 
    }
 

 
    render() {

        return (
         <div>
             <div className="BodyImage">
                <Container>
                    <Row className="Desserts"> {/** Desserts */}
                    <Col md="12" sm="12" xs="12">
                    <br/>
                        <h1>Thank You </h1>
                        <br/>
                        <h4 className="SuccessMsg">Your catering  order has been received,  Our events management team will contact you shortly to assist planning your event.</h4> 
                        <br/>
                    </Col> 
                    </Row>
                </Container>
             </div>
         </div>   
        );
    }
}

export default SuccessCategring;

