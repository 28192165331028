import React, { Component } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Global from '../Global.json';
import LinesEllipsis from "react-lines-ellipsis";
import TextTruncate from 'react-text-truncate';
export class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      todosPerPage: 8,
      cat_name: props.match.params.cat_name,
      isPageLoad: true,
      Products: [],
      Cat_id: 0,
      CatName: "",
      area: localStorage.getItem('area') ? localStorage.getItem('area') : "Qatar",
    }
    this.handleClick = this.handleClick.bind(this);
  }


  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id)
    });
  }

  componentWillMount() {
    fetch(Global.api_link + 'getCatID', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: Global.token,
        cat_name: this.state.cat_name,
        lang: 'english'
      }),
    }).then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            Cat_id: result.data.id,
            CatName: result.data.name,
          })
          this.Products()
        }

      );
  }

  Products() {
    fetch(Global.api_link + 'getProducts', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: Global.token,
        lang: "english",
        cat_id: this.state.Cat_id
      }),
    }).then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            Products: result.data,
            isPageLoad: false,
          })
        }

      );
  }


  descriptionProduct(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }

  createMarkup(text) {
    return { __html: text };
  }


  render() {

    const { Products, currentPage, todosPerPage } = this.state;

    // Logic for displaying current Products
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = Products.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos.map((Dess, index) => {
      return (

        <Col lg="3" md="4" sm="4" xs="12">
          <Card>
            <a href={"/ProductDetails/" + this.state.cat_name + "/" + Dess.id} params={Dess.id}>
              <div className="ProductIMG" key={Dess.id} style={{ backgroundImage: "url(" + Global.image_link + Dess.image + ")", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "center" }}>
              </div>
              <Card.Body>
                <Card.Title>{Dess.name}</Card.Title>
                <Card.Text>
                  {this.descriptionProduct(Dess.desc)}
                </Card.Text>
                <h3>{Dess.price ? "QR" + Dess.price : null}</h3>
              </Card.Body>
            </a>
          </Card>
        </Col>
      )
    });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(Products.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <li
          key={number}
          id={number}
          onClick={this.handleClick}
          className={this.state.currentPage == number ? 'active' : null}
        >
          {number}
        </li>
      );
    });
    if (this.state.isPageLoad) {

      return <div>
        <div className="Loader">
          <img alt="JaDough" src="/img/giphy.gif" />
        </div>
      </div>;
    } else {
      return (
        <div>
          <div className="BodyImage">
            <Container>
              <Row className="Desserts"> {/** Desserts */}
                <Col md="12" sm="12" xs="12">
                  <h1>{this.state.CatName}</h1>
                  {
                    this.state.cat_name == 'regular_cakes' ?
                      <h2>Customized Cakes and Full Cakes need 3 days prior notice .</h2>
                      :
                      null
                  }

                </Col>

                <Col md="12" sm="12" xs="12">
                  {
                    this.state.cat_name == 'Meal_Plan' ?
                    <iframe src="https://jadough.com/meal_plan.pdf" style={{width:"100%",height:800}} frameborder="0"></iframe>
                      :
                      null
                  }

                </Col>

                {renderTodos}
                {this.state.cat_name=='customized_cakes' ?
                <div>
                  <p>
                    Cake Builder Coming Soon!
                  </p>
                  <a className="CustomizedCake" href="/Catering/4">Customized Cakes Catering</a>
                </div>
                :null}
                <Col md="12" sm="12" xs="12" className="Pagination">
                  <ul id="page-numbers">
                    {renderPageNumbers}
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }
}

export default Products;

