import React, { Component } from "react";  
import { Container , Row , Col  } from 'react-bootstrap';  
export class Success extends Component { 
    constructor(props) {
        super(props); 
        this.state = {  
          value : 0,
          user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
          guest_id: localStorage.getItem("guest_id") ? localStorage.getItem("guest_id"): 0,
         
        } 
    }
 

 
    render() {

        return (
         <div>
             <div className="BodyImage">
                <Container>
                    <Row className="Desserts SuccessPage"> {/** Desserts */}
                    <Col md="12" sm="12" xs="12">
                    <br/>
                        <h1>Thank You </h1>
                        <br/>
                        <h4 className="SuccessMsg">Your order has been received and is being processed by our team.</h4> 
                        <br/>
                        {
                            this.state.user_id !== 0 ? <a className="SuccessOrder" href="/Orders"> Review Your Orders</a> : null
                        } 
                    </Col> 
                    </Row>
                </Container>
             </div>
         </div>   
        );
    }
}

export default Success;

