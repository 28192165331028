import React, { Component } from "react";  
import { Container , Row , Col , Table } from 'react-bootstrap';  
import Global from '../Global.json'; 

export class Orders extends Component { 
    constructor(props) {
        super(props); 
        this.state = {  
          value : 0,
          user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
          guest_id: localStorage.getItem("guest_id") ? localStorage.getItem("guest_id"): 0,
          Orders : []
        } 
    }
    
   decrease = () => {
        if(this.state.value != "0") {
            this.setState({ value: this.state.value - 1 });
        } 
        
      }
    
      increase = () => {
        this.setState({ value: this.state.value + 1 });
      }

    componentWillMount(){
      this.getUserCart(); 
    }

getUserCart() {
      fetch(Global.api_link + 'getUserOrdersHistory', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token : Global.token,
          user_id : this.state.user_id !== 0 ? this.state.user_id : this.state.guest_id
        }),
      }).then((response) => response.json())
        .then(
            (result) => {   
                this.setState({
                  Orders : result.data
                })
             } 
        );   
    }
updateUserCartQTY (pid , qty) {
        console.log(pid , qty) 
         fetch(Global.api_link + "updateUserCartQTY", {
          method: "POST",
          headers: {
            Accept: "application/json"
          },
          body: JSON.stringify({
            token:  Global.token,
            cart_id: pid,
            qty: qty
          })
        })
          .then(response => response.json())
          .then(result => {
            window.location.reload()
          })
          .catch(error => {
            console.error(error);
          }); 
}
 
    render() {
        let userID = this.state.user_id != 0 ? this.state.user_id : this.state.guest_id
        return (
         <div>
             <div className="BodyImage">
                <Container>
                    <Row className="Desserts"> {/** Desserts */}
                    <Col md="12" sm="12" xs="12">
                        <h1>Your Orders List</h1> 
                        {this.state.Orders.length >0 ?
                        <Table responsive> 
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>Order Date</th> 
                                <th className="Delete">View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                this.state.Orders.map(Order => {
                                    return(
                                    <tr>
                                        <td>{Order.id}</td>
                                        <td>{Order.complete_date_time	}</td>
                                        <td className="Delete"> 
                                            <a target="a_blank" href={"https://merwas.net/jadough/Webapi/invoiceLink?token=43abc9a4f620c34d3bbb863dd6c1e472&hash="+ Order.id +"&user_id="+ userID }>
                                                <i class="fa fa-eye" aria-hidden="true"></i>
                                            </a>
                                        </td>
                                    </tr> 
                                    )
                                })  } 

                            </tbody>
                            </Table>
                            : <h4>Your Orders List is Empty</h4>  } 
                    </Col> 
                    </Row>
                </Container>
             </div>
         </div>   
        );
    }
}

export default Orders;

