import React, { Component } from "react"; 
import { Container , Row , Navbar , Nav , NavDropdown , Col , Modal} from 'react-bootstrap';
import Global from '../Global.json'; 

export class Header extends Component { 
    constructor(props) {
        super(props); 
        this.state = { 
            user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
            Name : "",
            Categories : [],
            Specialties : [],
            isShowing : false,
            counter : 0,
            Settings : [],
            isPageLoad : true,
            guest_id: localStorage.getItem("guest_id") ? localStorage.getItem("guest_id"): 0,
        } 
    }
    
    componentWillMount () {
        if (this.state.guest_id == 0) {
            //console.log(this.state.guest_id);
            fetch(Global.api_link + "generateGuestID", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                token: Global.token
              })
            })
              .then(response => response.json())
              .then(result => {
                // console.log(result);
                localStorage.setItem("guest_id", result.data);
              });
          }
          
       
         if(this.state.user_id !== 0) {
            fetch(Global.api_link + 'getUserProfile', {
                method: 'POST', 
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }, 
                body: JSON.stringify({
                  token : Global.token,
                  user_id:this.state.user_id, 
                }),
              }).then((response) => response.json())
                .then(
                    (result) => {   
                       this.setState({
                           Name :result.data ? result.data.name : null
                       })
                       console.log(result)
                     }
                     
                );  
         }
          this.getSettings();
          this.getCategories() ;
          this.getUserCartCounter();
          this.getMainSpecialties()
          
      }

  getSettings() { 
    fetch(Global.api_link + 'getSettings', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token : Global.token, 
          lang : "english"
        }),
      }).then((response) => response.json())
        .then(
            (result) => {   
               this.setState({
                   Settings :result.data,
                   isPageLoad : false
               }) 
             }
             
        );  
  }
    getUserCartCounter () {
        fetch(Global.api_link + 'getUserCartCounter', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token : Global.token,
              user_id:this.state.user_id !== 0 ? this.state.user_id : this.state.guest_id, 
            }),
          }).then((response) => response.json())
            .then(
                (result) => {   
                   this.setState({
                       counter :result.data
                   })
                   console.log(result)
                 }
                 
            );  
     }
    
     handleCloseModal() {
        this.setState({ isShowing: false });
        window.location.reload();
    }
    getCategories() {
        
        fetch(Global.api_link + 'getMainCategories', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token : Global.token,
              lang: "english"
            }),
          }).then((response) => response.json())
            .then(
                (result) => {   
                   this.setState({
                    Categories :result.data
                   })
                   console.log(result)
                 }
                 
            );  
     }

     getMainSpecialties() {
        fetch(Global.api_link + 'getMainSpecialties', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token : Global.token,
              lang: "english"
            }),
          }).then((response) => response.json())
            .then(
                (result) => {   
                   this.setState({
                    Specialties :result.data
                   })
                   console.log(result)
                 }
                 
            );  
     }

     Logout() {
         localStorage.removeItem("user_id" , 0)
         localStorage.removeItem("area" , 'Choose Your Area')
         window.location.href="/"
     }
    render() {
      if (this.state.isPageLoad) {

        return <div> 
            <div className="Loader">
                <img alt="JaDough" src="img/giphy.gif" />
            </div>
        </div>;
    }else {   
    return (
         <div>
             <div className="Header">
                <Container>
                    <Row>
                        <Col md="2" sm="2" xs="2" className="Logo">
                            <a href="/"><img alt="JaDough" src={Global.image_link + this.state.Settings.logo} /></a>
                        </Col>
                        <Col md="10" sm="10" xs="10">
                            <div className="">
                                    <Navbar bg="light" expand="lg">
                                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                        <Navbar.Collapse id="basic-navbar-nav">
                                            <Nav className="mr-auto">
                                            <Nav.Link href="/About">Our Story</Nav.Link> 
                                            <NavDropdown title="Our Menu" id="basic-nav-dropdown">
                                                {this.state.Categories.map(Cat => {
                                                    return(
                                                        <NavDropdown.Item key={Cat.id} href={"/Products/" + Cat.machine_name} params={Cat.machine_name}>{Cat.name}</NavDropdown.Item>
                                                    )
                                                })} 
                                            </NavDropdown>

                                            <NavDropdown title="Our Specialties" id="basic-nav-dropdown">
                                                {this.state.Specialties.map(Cat => {
                                                    return(
                                                        <NavDropdown.Item key={Cat.id} href={"/Products/" + Cat.machine_name} params={Cat.machine_name}>{Cat.name}</NavDropdown.Item>
                                                    )
                                                })} 
                                            </NavDropdown>

                                            <NavDropdown title="Catering" id="basic-nav-dropdown">
                                                <NavDropdown.Item key={1} href={"/Catering/" +1} params={1}>Corporate Function</NavDropdown.Item>
                                                <NavDropdown.Item key={2} href={"/Catering/" + 2} params={2}>Private Event</NavDropdown.Item>
                                                <NavDropdown.Item key={3} href={"/Catering/" + 3} params={3}>Specialty Boxes</NavDropdown.Item>
                                                <NavDropdown.Item key={4} href={"/Catering/" + 4} params={4}>Customized Cake / Desserts</NavDropdown.Item>
                                            </NavDropdown>  
                                            <NavDropdown title="Upcoming Events" id="basic-nav-dropdown">
                                                <NavDropdown.Item key={1} href={"/events/" +1} params={1}>Spooktacular Party</NavDropdown.Item>
                                                <NavDropdown.Item key={2} href={"/events/" + 2} params={2}>Qatar National Day</NavDropdown.Item>
                                                <NavDropdown.Item key={3} href={"/events/" + 3} params={3}>Winter Festival Day</NavDropdown.Item>
                                                <NavDropdown.Item key={4} href={"/events/" + 4} params={4}>New Year’s Day</NavDropdown.Item>
                                            </NavDropdown> 

                                            <Nav.Link href="/GalleryPage">Gallery</Nav.Link> 
                                            <Nav.Link href="/Contact">Contact Us</Nav.Link>
                                            <Nav.Link data-dismiss="modal" onClick={() => {this.setState({ isShowing: true })}}><div className="CartHerder" > 
                                            <img alt="Cart" src="/img/cart.png" /><span>{this.state.counter}</span>  </div></Nav.Link>
                                            {this.state.user_id !== 0 ?
                                                <NavDropdown title={this.state.Name} id="basic-nav-dropdown">
                                                   <NavDropdown.Item key="1" href="/Profile"> Edit Profile</NavDropdown.Item>
                                                   <NavDropdown.Item key="2" href="/Orders"> My Orders</NavDropdown.Item>
                                                   <NavDropdown.Item key="3" onClick={()=> this.Logout()}> Logout</NavDropdown.Item>
                                                </NavDropdown> 
                                            
                                            :
                                            <Nav.Link href="/Login"> <div className="LoginDiv"><img alt="Cart" src="/img/profile.png" /> log-in/ register</div> </Nav.Link>
                                            }

                                            </Nav>
                                        </Navbar.Collapse>
                                    </Navbar>


                                    <Modal className="modal CartModalHeader" show={this.state.isShowing} close={this.closeModalHandler}  >
                                    <a className="CloseCart" data-dismiss="modal" onClick={() => {
                                            window.location.reload();
                                            }}
                                        >
                                            Close
                                        </a>

                                        <div className="Filters Filterxs">
                                            <h4>Shopping Cart </h4>

                                            <div>
                                                <div className="container">
                                                    <h3 className="gusetLog">Select a delivery type cart: </h3>
                                                </div>
                                                <div className="CartButtons">
                                                    <a className="btn btn-secondary " href="/Cart/1">
                                                        Instant Delivery Items
                                                    </a>
                                                    <a className="btn btn-primary " href="/Cart/2">
                                                        In preparation  Items
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div> 
                                </Modal>

                            </div>
                        </Col>
                    </Row>
                </Container>
             </div>
         </div>   
        );
    }}
}
export default Header;