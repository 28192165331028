import React, { Component } from "react";  
import { Container , Row , Col ,Modal } from 'react-bootstrap'; 
import Select  from "react-dropdown-select"; 
import { Fade } from 'react-slideshow-image';
import Global from '../Global.json';  

export class Home extends Component { 
    constructor(props) {
        super(props); 
        this.state = { 
            IMAGES : [],
            isPageLoad : true,
            locations : [],
            multi: true,
            isShowing: false, 
            disabled: false,
            loading: false,
            contentRenderer: false,
            dropdownRenderer: false,
            inputRenderer: false,
            itemRenderer: false,
            optionRenderer: false,
            noDataRenderer: false,
            selectValues: [],
            searchBy: "username",
            clearable: false,
            searchable: true,
            create: false,
            addPlaceholder: " ",
            separator: false,
            forceOpen: false,
            handle: false, 
            labelField: "username",
            valueField: "email",
            color: "#0074D9",
            keepSelectedInList: false,
            closeOnSelect: false,
            Categories : [],
            dropdownPosition: "bottom",
            direction: "ltr",
            dropdownHeight: "300px",
            area : localStorage.getItem('area') ? localStorage.getItem('area') : "Choose Your Area",

    } 
}
    setValues = selectValues => this.setState({ selectValues });
    
    
      noDataRenderer = () => {
        return (
          <p style={{ textAlign: "center" }}>
            <strong>Ooops!</strong> No data found
          </p>
        );
      };

    componentWillMount () { 
      this.getProductGallery();
      this.getLocations();
      this.getCategories(); 
    }
      getProductGallery() {
        fetch(Global.api_link + 'getProductGalleryHome', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                    this.setState({
                        IMAGES: result.data,
                        isPageLoad : false
                    }); 
                }
            );
    }  
    
    getCategories() {
        
        fetch(Global.api_link + 'getMainCategories', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token : Global.token,
              lang: "english"
            }),
          }).then((response) => response.json())
            .then(
                (result) => {   
                   this.setState({
                    Categories :result.data
                   })
                   console.log(result)
                 }
                 
            );  
     }


    getLocations () { 
      fetch(Global.api_link + 'getMainLocations', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            token: Global.token,
            lang : "english"
        }),
    }).then((response) => response.json())
        .then(
            (result) => {
                this.getcityCost();
                this.setState({
                    locations: result.data
                }); 
            }
        );

    }

    getcityCost() {
       
       fetch(Global.api_link + 'getcityCost', {
           method: 'POST',
           headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json',
           },
           body: JSON.stringify({
               token: Global.token,
               loc_name : this.state.area
           }),
       }).then((response) => response.json())
           .then(
               (result) => {
        
                 if(result.data){
                   this.setState({
                       minimunCost : result.data.minimum_cost,
                       message_id : parseInt(result.data.message_id),
                       isPageLoad : false
                   }); 

                   
                 }else{
                   this.setState({
                       isPageLoad : false
                   }); 
                 }
                   
               }
           );
     }

    Order() {
        if((this.state.selectValues.length > 0 && parseInt(this.state.selectValues[0].messageId) != 4) || (parseInt(this.state.message_id) != 4 && this.state.selectValues.length == 0)) { 
            localStorage.setItem('area' ,this.state.selectValues[0].name ) ;
             
            this.setState({
                isShowing : true
            })
        } 
       else{
            this.setState({
                isShowing : true
            })
       }
       
    }
    
    handleCloseModal() {
        this.setState({ showModal: false });
        window.location.reload();
      }

      
    render() { 

          const fadeProperties = {
            duration: 2000,
            transitionDuration: 100,
            infinite: true,
            indicators: false,
            arrows : false,

            onChange: (oldIndex, newIndex) => {
              console.log(`fade transition from ${oldIndex} to ${newIndex}`);
            }
          }
           
          const options = [];

          if (this.state.locations) {
              this.state.locations.map(loc => {
                  
                options.push({
                    key : loc.id,
                    id: loc.id,
                    name: loc.name,
                    username: loc.name, 
                    minCharge:loc.minimum_cost,
                    messageId : loc.message_id
                  });
              })
          }
          
        if (this.state.isPageLoad) {

            return <div> 
                <div className="Loader">
                    <img alt="JaDough" src="img/giphy.gif" />
                </div>
            </div>;
        }else {   
        return (
         <div>
             <div className="BodyImage">
                <Container>
                    <Row> {/** Select Location */}
                        <Col md="12" sm="12" xs="12" className="Logo">
                           <div className="LocationSelect">
                                <h1>Creation by inspiration</h1>
                                <div className="SelectDiv"> 
                                    <h4 className="TitleSelectDiv">Choose your area</h4>
                                   
                                    <Col md="12" sm="12" xs="12" className="LocationForm">
                                    <Col md="10" sm="10" xs="12" className="no_pad">
                                      <img src="/img/map.png"/>   
                                        <Select options={options} 
                                            onChange={(values) => this.setValues(values)} 
                                            //values={[options]}
                                            placeholder={this.state.area ? this.state.area :"Select Location"}
                                            addPlaceholder={this.state.addPlaceholder}
                                            color={this.state.color}
                                            disabled={this.state.disabled}
                                            loading={this.state.loading}
                                            searchBy={this.state.searchBy}
                                            separator={this.state.separator}
                                            clearable={this.state.clearable}
                                            searchable={this.state.searchable}
                                            create={this.state.create}
                                            keepOpen={this.state.forceOpen}
                                            dropdownHandle={this.state.handle}
                                            dropdownHeight={this.state.dropdownHeight}
                                            direction={this.state.direction}
                                            multi={this.state.multi}
                                            labelField={this.state.labelField}
                                            valueField={this.state.valueField} 
                                            dropdownGap={5}
                                            keepSelectedInList={this.state.keepSelectedInList}
                                            noDataLabel="No matches found"
                                            closeOnSelect={this.state.closeOnSelect}
                                            noDataRenderer={
                                            this.state.noDataRenderer
                                                ? () => this.noDataRenderer()
                                                : undefined
                                            }
                                        />
                                        </Col>
                                        <Col md="2" sm="2" xs="12" className="no_pad ButtonOrder">
                                            <a  className="OrderBut" onClick={() => this.Order()}>Order Now</a>
                                        </Col>
                                        
                                    </Col>
                                </div>
                                
                            </div> 
                        </Col>
                    </Row>

                    <Row> {/** Slideshow */}
                        <Col className="CakeHome"> 
                            <div className="slide-container">
                                <Fade {...fadeProperties}>

                                {
                                  this.state.IMAGES.map(img => {
                                    return(
                                      <div  key={img.id} className="each-fade">
                                          <div  key={img.id} className="image-container">
                                              <div className="SlideShow" key={img.id} style={{backgroundImage:"url(" +  Global.image_link + img.image + ")"   , backgroundRepeat : 'no-repeat' , backgroundSize : 'cover' , backgroundPosition : "center" }}>

                                              </div> 
                                          </div>
                                      </div>
                                    )
                                  })
                                }
                                 
                                </Fade>
                            </div>
                        </Col> 
                    </Row>

                    <Modal  className="modal" show={this.state.isShowing}  close={this.closeModalHandler}  >
                          
                          
                          <div className="Filters Filterxs"> 
                            
                                    <div >
                                        { (this.state.selectValues.length > 0 && parseInt(this.state.selectValues[0].messageId) != 4) || (parseInt(this.state.message_id) != 4 && this.state.selectValues.length == 0) ?
                                       
                                       <div>
                                       <h4>Select Category </h4>
                            
                                        <div>
                                            
                                            <div className="container">
                                               <ul className="CategoryHome">
                                                   
                                                   {this.state.Categories.map(Cat => {
                                                       return(
                                                           <li>
                                                                <a href={"/Products/" + Cat.machine_name}>
                                                                    {Cat.name}
                                                                </a>
                                                            </li>
                                                       )
                                                   })} 
                                               </ul>
                                            </div>
                                        </div>

                                        <h4 className="CostMsgError">The minimum order amount for your area is :   {this.state.selectValues.length > 0 ? this.state.selectValues[0].minCharge : this.state.minimunCost} QR</h4>
                                        </div>
                                        :  
                                        <h4>Instant delivery coming soon in your area</h4> 
                                        }
                                    </div>
                            </div> 

                        
                            <div class="modal-footer">
                                <a  class="btn btn-secondary " data-dismiss="modal" onClick={() => {
                                    this.setState({isShowing: false})
                                    }}
                                >
                                   Close
                                </a> 
                                { (this.state.selectValues.length > 0 && parseInt(this.state.selectValues[0].messageId) != 4) || (parseInt(this.state.message_id) != 4 && this.state.selectValues.length == 0) ?
                                    null
                                    : 
                                    <a  class="btn OrderLater"  href="/Catering/3">
                                        Order Later
                                    </a>  
                                }
                            </div>
                        </Modal>
                </Container>
             </div>
         </div>   
        );
    }
}
}
export default Home;

