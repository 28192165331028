import React, { Component } from "react";
import { Container, Row, FormControl, Button, InputGroup, Col } from 'react-bootstrap';
//import PropTypes from 'prop-types'; 
import Gallery from 'react-grid-gallery';
import Global from '../Global.json';

export class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            IMAGES: [],
            isPageLoad: false,
            Settings: []
        }

    }

    componentWillMount() {
        this.getSettings();
        this.getProductGallery()
    }

    getProductGallery() {
        fetch(Global.api_link + 'getFooterGallery', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                    this.setState({
                        IMAGES: result.data,
                    });

                    console.log(this.state.IMAGES)

                }
            );
    }

    getSettings() {
        fetch(Global.api_link + 'getSettings', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token,
                lang: "english"
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                    this.setState({
                        Settings: result.data
                    })
                }

            );
    }

    addNewsletterEmail() {
        fetch(Global.api_link + 'addNewsletterEmail', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token,
                email: this.state.Email
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                    this.setState({
                        isPageLoad: false,
                    })
                }
            );
    }
    render() {
        const IMAGES = [];

        if (this.state.IMAGES) {
            this.state.IMAGES.map(img => {
                IMAGES.push({
                    src: Global.image_link + img.image,
                    thumbnail: Global.image_link + img.image,
                    thumbnailWidth: 20,
                    thumbnailHeight: 20,
                    caption: img.name_en
                });
            })
        }

        if (this.state.isPageLoad) {
            return <div>
                <div className="Loader">
                    <img alt="JaDough" src="/img/giphy.gif" />
                </div>
            </div>;
        } else {
            return (
                <div>
                    <div className="Footer">
                        <div className="FooterLayer">
                            <Container>
                                <Row>
                                    <Col md="4" sm="4" xs="12">
                                        <img alt="Jadough" src="/img/logo.png" /> 
                                    </Col>
                                   
                                    <Col md="4" sm="4" xs="9">
                                        {
                                            /**
                                             *  <ul>
                                            <li><a href="/About">About</a></li>
                                            <li><a href="/Delivery">Delivery</a></li>
                                            <li><a href="/Order">How to order</a></li>
                                            <li><a href="/About">Exchange & Return Policy</a></li>
                                            <li><a href="/About">Payment Methods</a></li>
                                            <li><a href="/Contact">Contact Us</a></li>
                                        </ul>
                                             */
                                        }

                                        <h3>Subscribe Now</h3>  <br/>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                placeholder="name@example.com"
                                                aria-label="name@example.com"
                                                aria-describedby="basic-addon2"
                                                name="email"
                                                onChange={(email) => this.setState({ Email: email.target.value })}
                                            />
                                            <InputGroup.Append>
                                                <Button type="submit" variant="outline-secondary" onClick={() => {
                                                    this.setState(
                                                        {
                                                            isPageLoad: true,
                                                        },
                                                        () => {
                                                            this.addNewsletterEmail();
                                                        }
                                                    );
                                                }}><i className="fas fa-paper-plane"></i></Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                       
                                    </Col>
                                    <Col md="4" sm="4" xs="12" className="SocialMediaFooter">
                                    <h3>Find Us On</h3>
                                        <ol>
                                            <li><a target="_blank" href={this.state.Settings.facebook}><i className="fab fa-facebook"></i></a></li>
                                            <li><a target="_blank" href={this.state.Settings.twitter}><i className="fab fa-twitter"></i></a></li>
                                            <li><a target="_blank" href={this.state.Settings.instagram}><i className="fab fa-instagram"></i></a></li>
                                        </ol>
                                    </Col>
                                    {/** 
                                    <Col md="3" sm="3" xs="12" className="Gallery">
                                        <div>
                                            <h3><a href="/GalleryPage" >Gallery</a></h3>
                                            <Gallery images={IMAGES}
                                                thumbnailWidth={20}
                                                enableImageSelection={false}
                                            />

                                        </div>
                                        <div>
                                            <h5> Copyright 2019 JaDough</h5>
                                        </div>
                                    </Col>
                                    */}
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default Footer;