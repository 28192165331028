import React, { Component } from "react";  
import { Container , Row , Col , Form } from 'react-bootstrap';  
import Global from '../Global.json'; 

export class Profile extends Component { 
    constructor(props) {
        super(props); 
        this.state = {  
          value : 0,
          user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
          Cart : [],
          Profile : [],
          Total :[],
          isPageLoad : false,
          locations : []
        } 
    }

decrease = () => {
    if(this.state.value != "0") {
        this.setState({ value: this.state.value - 1 });
    }    
}
    
increase = () => {
    this.setState({ value: this.state.value + 1 });
}

componentWillMount(){ 
    this.getUserCart();
    this.getUserProfile();
    this.getLocations()
}

getUserCart(){
    fetch(Global.api_link + 'getUserCart', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token : Global.token,
          user_id : this.state.user_id,
          lang : 'english'
        }),
      }).then((response) => response.json())
        .then(
            (result) => {   
                this.setState({
                  Total : result.total
                })
             } 
        ); 

}

getUserProfile() {
    fetch(Global.api_link + 'getUserProfile', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token : Global.token,
          user_id : this.state.user_id
        }),
      }).then((response) => response.json())
        .then(
            (result) => {   
                this.setState({
                  Name : result.data.name, 
                  Phone : result.data.phone,
                  Location : result.data.address, 
                  EmailSignUp : result.data.email, 
                  Name : result.data.name,  
                })
             } 
        );   
}
 


userUpdateProfile () { 
        fetch(Global.api_link + 'userUpdateProfile', {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token : Global.token,
            password : this.state.Password ? this.state.Password : "" ,
            name : this.state.Name, 
            phone : this.state.Phone,
            address : this.state.Location, 
            email : this.state.EmailSignUp,   
            city : this.state.City ? this.state.City : 1, 
            user_id : this.state.user_id
          }),
        }).then((response) => response.json())
          .then(
              (result) => {   
                this.setState({
                    isPageLoad : false
                })
                window.location.reload()
               }
               
          ).catch(error=>{
        
          });   
  }


  getLocations() { 
    fetch(Global.api_link + 'getMainLocations', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          token: Global.token,
          lang : "english"
      }),
  }).then((response) => response.json())
      .then(
          (result) => {
              this.setState({
                  locations: result.data,
                  isPageLoad : false
              }); 
          }
      );

  }

    render() {

        if(this.state.user_id == "0") {
            window.location.href="/"
        }

        
        if (this.state.isPageLoad) { 
            return <div> 
                <div className="Loader">
                    <img alt="JaDough" src="/img/giphy.gif" />
                </div>
            </div>;
        }else { 
        return (
         <div>
             <div className="BodyImage">
                <Container>
                    <Row className="Desserts"> {/** Desserts */}
                    <Col md="12" sm="12" xs="12">
                        <h1>Your Account</h1>  
                        
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="name"  name="name" value={this.state.Name} onChange={(name) => this.setState({Name : name.target.value})} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" placeholder="phone" name="phone"  value={this.state.Phone}  onChange={(phone) => this.setState({Phone : phone.target.value})} />
                            </Form.Group>
                        </Form.Row>
 

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="email" name="email"  value={this.state.EmailSignUp} onChange={(email) => this.setState({EmailSignUp : email.target.value})} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Address </Form.Label>
                                <Form.Control type="text" placeholder="location"  name="location"  value={this.state.Location} onChange={(location) => this.setState({Location : location.target.value})} />
                            </Form.Group>
                        </Form.Row> 

                        <Form.Row>
                             <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="*****" name="password"  onChange={(password) => this.setState({Password : password.target.value})} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
                                <Form.Label>Area</Form.Label>
                                <Form.Control as="select" name="city" onChange={(city) => this.setState({City: city.target.value})}>
                                <option value="0" >Choose Area</option>
                                    {this.state.locations.map(loc => {
                                        return(
                                            <option  value={loc.name} selected= {this.state.area == loc.name  ? 'selected' : null}>{loc.name}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group> 

                        </Form.Row>
                        <Col md="12" sm="12" xs="12" className="OrderNOwContainer">
                            <a onClick={() => {
                                        this.setState(
                                            {
                                            isPageLoad: true, 
                                            },
                                            () => {
                                            this.userUpdateProfile();
                                            }
                                        );
                                        }} className="OrderNOw">Save</a>
                        </Col>
                    </Col> 
                    </Row>
                </Container>
             </div>
         </div>   
        );
    }
}
}

export default Profile;

