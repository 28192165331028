import React from "react";
import { Route, BrowserRouter } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Products from "./components/Products";
import ProductDetails from "./components/ProductDetails";
import Cart from "./components/Cart";
import Contact from "./components/Contact";
import Login from "./components/Login";
import Checkout from "./components/Checkout";
import Register from "./components/Register";
import Profile from "./components/Profile";
import Orders from "./components/Orders";
import Success from "./components/Success";
import Gallery from "./components/GalleryPage";
import Catering from "./components/Catering";
import SuccessCategring from "./components/SuccessCategring";
import events from "./components/events";

import HttpsRedirect from "react-https-redirect";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <HttpsRedirect>
      <BrowserRouter basename="/">
        <div>
          <div>
            <Header />
            <Route path="/" component={Home} exact />
            <Route path="/About" component={About} exact />
            <Route path="/Products/:cat_name" component={Products} />
            <Route
              path="/ProductDetails/:cat_name/:product_id"
              component={ProductDetails}
            />
            <Route path="/Cart/:CartID" component={Cart} exact />
            <Route path="/Contact" component={Contact} exact />
            <Route path="/Login" component={Login} exact />
            <Route path="/Register" component={Register} exact />
            <Route path="/Profile" component={Profile} exact />
            <Route path="/Checkout/:CartID" component={Checkout} exact />
            <Route path="/Orders" component={Orders} exact />
            <Route path="/Success" component={Success} exact />
            <Route path="/Catering/:Cate_id" component={Catering} exact />
            <Route path="/GalleryPage" component={Gallery} exact />
            <Route path="/events/:event_id" component={events} exact />
            <Route
              path="/SuccessCategring"
              component={SuccessCategring}
              exact
            />
          </div>
        </div>
        <Footer />
      </BrowserRouter>
    </HttpsRedirect>
  );
}

export default App;
