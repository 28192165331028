import React, { Component } from "react";  
import { Container , Row , Col , Table } from 'react-bootstrap';  
import Global from '../Global.json'; 

export class Cart extends Component { 
    constructor(props) {
        super(props); 
        this.state = {  
          value : 0,
          user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
          guest_id: localStorage.getItem("guest_id") ? localStorage.getItem("guest_id"): 0,
          Cart : [],
          Total : 0,
          CartID: props.match.params.CartID,
          isPageLoad:true
        } 
    }
   decrease = () => {
        if(this.state.value != "0") {
            this.setState({ value: this.state.value - 1 });
        } 
        
      }
    
      increase = () => {
        this.setState({ value: this.state.value + 1 });
      }

    componentWillMount(){
     
      this.getUserCart()  
    }

getUserCart() {

  if(this.state.CartID == 1) {
      fetch(Global.api_link + 'getUserCart', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token : Global.token,
          user_id : this.state.user_id !== 0 ? this.state.user_id : this.state.guest_id,
          lang : 'english'
        }),
      }).then((response) => response.json())
        .then(
            (result) => {   
                this.setState({
                  Cart : result.data, 
                  Total : result.total,
                  isPageLoad:false
                })
             } 
        );   
    }else {
      fetch(Global.api_link + 'getUserCartLater', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token : Global.token,
          user_id : this.state.user_id !== 0 ? this.state.user_id : this.state.guest_id,
          lang : 'english'
        }),
      }).then((response) => response.json())
        .then(
            (result) => {   
                this.setState({
                  Cart : result.data, 
                  Total : result.total,
                  isPageLoad:false
                })
             } 
        );   
    }

  }

updateUserCartQTY (pid , qty) {
        console.log(pid , qty) 
         fetch(Global.api_link + "updateUserCartQTY", {
          method: "POST",
          headers: {
            Accept: "application/json"
          },
          body: JSON.stringify({
            token:  Global.token,
            cart_id: pid,
            qty: qty
          })
        })
          .then(response => response.json())
          .then(result => {
            window.location.reload()
          })
          .catch(error => {
            console.error(error);
          }); 
}

deleteCart(pid) {
  fetch(Global.api_link + "deleteCart", {
    method: "POST",
    headers: {
      Accept: "application/json"
    },
    body: JSON.stringify({
      token:  Global.token,
      cart_id: pid
    })
  })
    .then(response => response.json())
    .then(result => {
      window.location.reload()
    })
    .catch(error => {
      console.error(error);
    });  
}

CheckTime() {
  var date = new Date().getHours(); 
        if(date >= 8 && date <= 21) {
          this.state.CartID == 1 ?
          window.location.href="/Checkout/1"
          :
          window.location.href="/Checkout/2"
        }else {
          alert("Ops! We can't received your order at this time")
        }
}


ForLater() {
  window.location.href = "/"
}
    render() {
      if (this.state.isPageLoad) {

        return <div> 
            <div className="Loader">
                <img alt="JaDough" src="img/giphy.gif" />
            </div>
        </div>;
    }else {  
      return (
        <div>
            <div className="BodyImage">
               <Container>
                   <Row className="Desserts"> {/** Desserts */}
                  
                   <Col md="12" sm="12" xs="12">
                       <h1>Your Shopping Cart</h1> 
                       {this.state.Cart ?
                       <Table responsive> 
                           <thead>
                               <tr>
                               <th>Item</th>
                               <th>Name</th>
                               <th className="QuantityTable">Quantity</th>
                               <th>Amount</th>
                               <th className="Delete">Delete</th>
                               </tr>
                           </thead>
                           <tbody>
                               {
                               this.state.Cart.map(cart => {
                                   return(
                                       <tr>
                                       <td><img alt="JaDough" src={Global.image_link + cart.image}/></td>
                                       <td>
                                         {cart.name}
                                         <br/>
                                          {cart.options ? "Options : " + cart.options : null }{ cart.options && cart.op_price != 0 ? "  + QR " +cart.op_price  : null }
                                       </td>
                                       <td>
                                           <div className="def-number-input number-input">
                                               <button  onClick={
                                               ()=> {
                                                   this.updateUserCartQTY(cart.cart_id , parseInt(parseInt(cart.qty) - 1))
                                               }
                                           } className="minus"></button>
                                               <input className="quantity" name="quantity" value={cart.qty} onChange={()=> console.log('change')}
                                               type="number" />
                                               <button  onClick = {
                                               ()=> {
                                                   this.updateUserCartQTY(cart.cart_id , parseInt(parseInt(cart.qty) + 1))
                                               }
                                           } className="plus"></button> 
                                           </div>   
                                       </td> 

                                       <td>QR {cart.options && cart.op_price != 0 ? parseFloat((parseFloat(cart.price) +parseFloat(cart.op_price)) *  parseInt(cart.qty)) : parseFloat(parseFloat(cart.price) * parseInt(cart.qty))} </td>
                                       <td className="Delete"><a onClick={()=> this.deleteCart(cart.cart_id)}> <i class="fa fa-trash" aria-hidden="true"></i></a></td>
                                       </tr> 
                                   )
                               })  } 

                                 <tr>  
                                 <td colspan="2">
                                       <a href="/" className= "BackButt"> Back to Products</a>
                                   </td>
                                    <td colspan="3" className="TotalTable">
                                       <h5>Total : QR {this.state.Total} </h5>
                                   </td>

                                 </tr> 
                           </tbody>
                           </Table>
                           : <h2>Your Cart is Empty</h2>  }
                            {parseInt(this.state.Cart.length) > 0 ?
                           <Col md="12" sm="12" xs="12" className="OrderNOwContainer"> 
                           <a className="OrderNOwBack"   onClick ={() => this.ForLater()}>Save for later </a>  
                           <a onClick={() =>this.CheckTime()}  className="OrderNOw">Checkout </a>
                           
                           </Col>
                           : null }
                   </Col> 
                   </Row>
               </Container>
            </div>
        </div>   
       );
    }
        
    }
}

export default Cart;

