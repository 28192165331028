import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Global from '../Global.json';

export class Catering extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: true,
            activeMarker: {},
            isPageLoad: true,
            selectedPlace: {},
            Settings: [],
            startDate: new Date(),
            Other : false,
            Cate_id: props.match.params.Cate_id,
            user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
        }
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
    }


    componentWillMount() {
        this.getSettings()
    }

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };



    uploadImage(e) {
        let localUri = this.state.Image;
        let filename = localUri.split("/").pop();

        // Infer the type of the image
        let match = /\.(\w+)$/.exec(filename);
        let type = match ? `image/${match[1]}` : `image`;

        // Upload the image using the fetch and FormData APIs
        let formData = new FormData();
        formData.append("photo", this.state.file);

        fetch(Global.api_link + "uploadImage", {
            method: "POST",

            body: formData
        })
            .then(response => response.json())
            .then(result => {

                this.setState(
                    {
                        Image: result
                    },
                    () => {
                        this.SpecialOrder();
                    }
                );

            });
    }

    SpecialOrder() {
        if (this.state.Name && this.state.Phone && this.state.Address && this.state.EmailSignUp && this.state.Guests  && this.state.Budget ) {
            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (reg.test(this.state.EmailSignUp) === false) {
                alert("Enter Valid Email Syntax")
                this.setState({
                    isPageLoad: false,
                });
            } else {

                let details = "";
                if(this.state.Type){
                   this.state.Type === "Others" ? details = details + "<br/>"+ "Function Type is : " + this.state.Other : details = details + "<br/>"+ " Function Type is : " + this.state.Type;
                }

                if(this.state.Theme){
                    details = details + "<br/>" + "Theme is : " + this.state.Theme;
                }

                if(this.state.Details){
                    details = details + "<br/>"+ "Other Details : " + this.state.Details;
                }

                fetch(Global.api_link + 'SpecialOrder', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: Global.token,
                        email: this.state.EmailSignUp,
                        name: this.state.Name,
                        phone: this.state.Phone,
                        details: details,
                        organization_name: this.state.Orgname ? this.state.Orgname : " ",
                        address: this.state.Address,
                        budget: this.state.Budget ? this.state.Budget : "",
                        guests: this.state.Guests ? this.state.Guests : "",
                        date: this.state.startDate,
                        image: this.state.Image ? this.state.Image : "",

                    }),
                }).then((response) => response.json())
                    .then(
                        (result) => {
                            window.location.href = "/SuccessCategring"
                        }
                    )
            }
        }
        else {
            alert("All Fields Required ")
            this.setState({
                isPageLoad: false,
            });
        }
    }


    onMarkerClick = (props, marker, e) => {

        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            windowProps: props
        });
    }

    onMapClick = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }

    }

    getSettings() {
        fetch(Global.api_link + 'getSettings', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token,
                lang: "english"
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                    this.setState({
                        Settings: result.data,
                        isPageLoad: false
                    })
                }

            );
    }



    descriptionProduct(text) {
        return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
    }

    createMarkup(text) {
        return { __html: text };
    }


    render() {
        if (this.state.isPageLoad) {

            return <div>
                <div className="Loader">
                    <img alt="JaDough" src="/img/giphy.gif" />
                </div>
            </div>;
        } else {
            return (
                <div>
                    <div className="BodyImage">
                        <Container>

                            <Row>
                                <Col md="12" sm="12" xs="12" className="SpecialOrder">
                                    <h1>{this.state.Cate_id == 1 ? "Corporate Function Catering" : this.state.Cate_id == 2 ? "Private Event Catering" : this.state.Cate_id == 3 ? "Specialty Boxes" : this.state.Cate_id == 4 ? "Customized Cake / Customized Desserts" : this.state.Cate_id == 5 ? "Other type of Catering" : null}</h1>

                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" placeholder="name" name="name" onChange={(name) => this.setState({ Name: name.target.value })} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="text" placeholder="phone" name="phone" onChange={(phone) => this.setState({ Phone: phone.target.value })} />
                                        </Form.Group>
                                    </Form.Row>


                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="email" name="email" onChange={(email) => this.setState({ EmailSignUp: email.target.value })} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Residential Address </Form.Label>
                                            <Form.Control type="text" placeholder="address " name="address" onChange={(address) => this.setState({ Address: address.target.value })} />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Estimated Guests (Choose 1)</Form.Label>
                                            <Form.Control as="select" name="guests" onChange={(guests) => this.setState({ Guests: guests.target.value })}>
                                                <option value="0" >Choose Guests</option>
                                                <option value={"10 - 20"} >10 - 20</option>
                                                <option value={"21 – 25"} >21 – 25</option>
                                                <option value={"26 – 30"} >26 – 30</option>
                                                <option value={"31 – 40"} >31 – 40</option>
                                                <option value={"41 – 50"} >41 – 50</option>
                                                <option value={"More than 50"} >More than 50</option>

                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Estimated Budget (Choose 1)</Form.Label>
                                            <Form.Control as="select" name="budget" onChange={(budget) => this.setState({ Budget: budget.target.value })}>
                                                <option value="0" >Choose Budget</option>
                                                <option value={"QR 500 – QR999"} >QR 500 – QR999</option>
                                                <option value={"QR 1000 – QR 2000"} >QR 1000 – QR 2000</option>
                                                <option value={"QR 2000 – QR 3000"} >QR 2000 – QR 3000</option>
                                                <option value={"QR 3000 – QR 4000"} >QR 3000 – QR 4000</option>
                                                <option value={"QR 4000 – QR 5000"} >QR 4000 – QR 5000</option>
                                                <option value={"Above QR 5000"} >Above QR 5000</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>

                                        {this.state.Cate_id == 1 ?

                                            <Form.Group as={Col} controlId="formGridEmail">
                                                <Form.Label>Organization Name</Form.Label>
                                                <Form.Control type="text" placeholder="name" name="Orgname" onChange={(Orgname) => this.setState({ Orgname: Orgname.target.value })} />
                                            </Form.Group>
                                            : null}

                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Date</Form.Label>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={this.handleChange}
                                                className="DatePic"
                                            />

                                        </Form.Group>

                                        {this.state.Cate_id == 5 || this.state.Cate_id == 2 ?
                                            <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
                                                <Form.Label>Type </Form.Label>
                                                <Form.Control as="select" name="Type" onChange={(Type) => this.setState({ Type: Type.target.value })}>
                                                    <option value="0" >Choose Type</option>
                                                    <option value={"Birthday"} >Birthday</option>
                                                    <option value={"Wedding"} >Wedding</option>
                                                    <option value={"Farewell"} >Farewell</option>
                                                    <option value={"Baby Shower"} >Baby Shower</option>
                                                    <option value={"Promotion"} >Promotion</option>
                                                    <option value={"Retirement"} >Retirement</option>
                                                    <option value={"Graduation"} >Graduation</option>
                                                    <option value={"Others"}>Others</option>
                                                </Form.Control>
                                                {
                                                    this.state.Type === "Others" ? 
                                                <Form.Group as={Col} controlId="formGridEmail" className="OtherInput">
                                                    <Form.Control type="text" placeholder="Write type here" name="other" onChange={(other) => this.setState({ Other: other.target.value })} />
                                                </Form.Group>
                                                    : null 
                                                }
                                                

                                            </Form.Group>
                                            : null}


                                        {this.state.Cate_id == 3 ?
                                            <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
                                                <Form.Label>Type (Choose 1)</Form.Label>
                                                <Form.Control as="select" name="Type" onChange={(Type) => this.setState({ Type: Type.target.value })}>
                                                    <option value="0" >Choose Type</option>
                                                    <option value={"Healthy Lunch Meals Daily/Weekly"} >Healthy Lunch Meals Daily/Weekly</option>
                                                    <option value={"Healthy Dinner Meals Daily/Weekly"} >Healthy Dinner Meals Daily/Weekly</option>
                                                    <option value={"Assorted Sandwich Box"} >Assorted Sandwich Box</option>
                                                    <option value={"Assorted Mini Bite Box"} >Assorted Mini Bite Box</option>
                                                    <option value={"Assorted Dessert Box"} >Assorted Dessert Box</option>
                                                    <option value={"Others"}>Others</option>
                                                </Form.Control>

                                                {
                                                    this.state.Type === "Others" ? 
                                                <Form.Group as={Col} controlId="formGridEmail" className="OtherInput">
                                                    <Form.Control type="text" placeholder="Write type here" name="other" onChange={(other) => this.setState({ Other: other.target.value })} />
                                                </Form.Group>
                                                    : null 
                                                }
                                                
                                                
                                            </Form.Group>
                                            : null}


                                    </Form.Row>


                                    {this.state.Cate_id != 3 ?

                                        <div>

                                            <Form.Row>
                                                <Form.Group as={Col} controlId="formGridPassword">
                                                    <Form.Label>Theme</Form.Label>
                                                    <Form.Control type="text" placeholder="Theme" name="Theme" onChange={(Theme) => this.setState({ Theme: Theme.target.value })} />
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridPassword">
                                                    <Form.Label>Image</Form.Label>
                                                    <Form.Control type="file" placeholder="Image" name="photo" onChange={(e) => {
                                                        this.setState({
                                                            file: e.target.files[0],
                                                            Image: e.target.value
                                                        });
                                                    }} />
                                                </Form.Group>
                                            </Form.Row>

                                        </div>
                                        : null}


                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Additional relevant information</Form.Label>
                                            <Form.Control as="textarea" rows="5" placeholder="Details" name="msg" onChange={(msg) => this.setState({ Details: msg.target.value })} />
                                        </Form.Group>
                                    </Form.Row>

                                    <Button variant="primary" type="submit" variant="outline-secondary" onClick={() => {
                                        this.setState(
                                            {
                                                isPageLoad: true,
                                            },
                                            () => {
 
                                                if (this.state.Cate_id != 3 && this.state.Theme !== null && this.state.startDate !== null) {
                                                    if(this.state.Image){
                                                        this.uploadImage();
                                                    }else{
                                                        this.SpecialOrder();
                                                    }
                                                    
                                                } else {
                                                    if(this.state.Cate_id == 1){
                                                        if (this.state.Cate_id == 1 && this.state.organization_name !== null && this.state.startDate !== null) {
                                                            this.SpecialOrder();
                                                        } else {
                                                            alert("All Fields Required")
                                                        }
                                                    }else{
                                                        if ((this.state.Cate_id == 2 || this.state.Cate_id == 3 || this.state.Cate_id == 5) && this.state.Type && this.state.startDate) {
                                                            this.SpecialOrder();
                                                         } else {
                                                             alert("All Fields Required")
                                                         }
                                                    }
                                                    

                                                    
                                                } 

                                            }
                                        );
                                    }}>
                                        Submit
                            </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            );
        }
    }
}

export default Catering;