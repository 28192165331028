import React, { Component } from "react";  
import { Container , Row , Col } from 'react-bootstrap';    
import Global from '../Global.json'; 

export class About extends Component { 
    constructor(props) {
        super(props); 
        this.state = { 
            Settings : []  ,
            isPageLoad : true
        } 
        
    }
  
    componentWillMount() {
        this.getSettings()
    }
     
    getSettings() { 
        fetch(Global.api_link + 'getSettings', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token : Global.token, 
              lang : "english"
            }),
          }).then((response) => response.json())
            .then(
                (result) => {   
                   this.setState({
                       Settings :result.data,
                       isPageLoad : false
                   }) 
                 }
                 
            );  
      }


      descriptionProduct(text) {
        return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
      }
      
      createMarkup(text) {
        return { __html: text };
      }
      
    render() {
         
        if (this.state.isPageLoad) {

            return <div> 
                <div className="Loader">
                    <img alt="JaDough" src="img/giphy.gif" />
                </div>
            </div>;
        }else {  
        return (
         <div>
             <div className="BodyImage">
                <Container>
                    <Row className="DessertsDet"> {/** Desserts */}
                    <Col md="12" sm="12" xs="12" className="About">
                        <h3>Our Story</h3>
                        <p>
                         {this.descriptionProduct(this.state.Settings.about)}
                        </p> 
                    </Col> 
                    </Row>
                </Container>
             </div>
         </div>   
        );
    }
}
}
export default About;

