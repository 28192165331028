import React, { Component } from "react";  
import { Container , Row , Col ,Form , Button , Modal } from 'react-bootstrap';    
import FacebookLogin from 'react-facebook-login';
import Global from '../Global.json'; 
import { withRouter } from 'react-router-dom'
export class Login extends Component { 
    constructor(props) {
        super(props); 
        this.state = {
            isPageLoad: false,  
            user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
            isShowing : false,
            Code : 0,
            isReset : false,
            RePassword : false
        } 
    }
  
componentWillMount() {
   
}

handleBack = () => {
  this.props.history.goBack()
}

Login () {
    if(this.state.Email && this.state.PasswordLogin != null){  
        fetch(Global.api_link + 'userLogin', {
          method: 'POST',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token : Global.token,
            email : this.state.Email,
            password : this.state.PasswordLogin
          }),
        }).then((response) => response.json())
          .then(
              (result) => {   
                 if(result.data) {
                   localStorage.setItem('user_id' , result.data )
                   this.handleBack()
                 }else{
                   alert("Email Or Password Wrong")
                 }
                 console.log(this.state.user_id)
               }
               
          );  
    }else{
      alert ('All Fields Required')
    }
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    window.location.reload();
  }


  Reset() {
    fetch(Global.api_link + 'sendEmailOTP', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token : Global.token,
        email : this.state.Reemail, 
      }),
    }).then((response) => response.json())
      .then(
          (result) => {   
             this.setState({
              Code : result.data,
              isShowing : false,
              isPageLoad : false,
              isReset: true
             })
             //console.log(this.state.Code)
            
           }

          
      );  
  }


  ResetPass () {
    if(this.state.Code == this.state.CheckCode) {
     this.setState({ 
      isReset : false,
      RePassword: true
     }) 

    }else{
      alert("Wrong Code")
    }
  }

  ResetPassConfirmation() {
   
    fetch(Global.api_link + 'changePasswordConfirm', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token : Global.token,
        email : this.state.Reemail,
        password : this.state.newPassword, 
      }),
    }).then((response) => response.json())
      .then(
          (result) => {   
             this.setState({  
              RePassword: false
             })  
           }
           
      ); 
      
  }
    render() {
        const responseFacebook = (response) => {
            console.log(response);

            fetch(Global.api_link + 'facebookLogin', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                },
                body: JSON.stringify({
                  isValidDevc: true,
                  email: response.email ? response.email : "",
                  name: response.name ? response.name : "", 
                  facebook_id : response.id ? response.id : ""
                }),
              }).then((response) => response.json())
                .then((result) => {
                 
                    localStorage.setItem('user_id', result.data);
                    window.location = "/";
                   console.log(response);
                }) 
                .catch((error) => { 
                  console.error(error);
                });

          } 
          if (this.state.isPageLoad) {

            return <div> 
                <div className="Loader">
                    <img alt="JaDough" src="img/giphy.gif" />
                </div>
            </div>;
        }else { 
          return (
         <div>
             <div className="BodyImage">
                <Container>
                    <Row>
                        <Col md="3" sm="2" xs="12"></Col>
                        <Col md="5"   xs="12" className="SpecialOrder Login">
                        {
                          /**
                           * 
                           *  <FacebookLogin
                            //appId="2419026215051317"
                            autoLoad={false}
                            icon="fa-facebook"
                            fields="id,name,email,phone"
                            //onClick={componentClicked}
                            callback={responseFacebook} />

                            <h4>OR</h4> <br/>
                           * 
                           */
                        }
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" name="email" onChange={(email) => this.setState({Email : email.target.value})} /> 
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" onChange={(password) => this.setState({PasswordLogin : password.target.value})}  />
                            </Form.Group>
                            
                            <Button variant="primary" type="submit" onClick={() => this.Login()}>
                                Login
                            </Button>

                            <Col  md="12" sm="12" xs="12" className="CreateAcc">
                                <a href="/Register"> Create your account</a> <br/>
                                <a className="forgetPass" onClick={()=>this.setState({isShowing: true})}> Forgot Password ? </a>
                            </Col>
                            
                        </Col>
                        <Col md="1"   xs="12"></Col>

                        <Modal  className="modal" show={this.state.isShowing}  close={this.closeModalHandler}  >  
                          <div className="Filters Filterxs"> 
                            <h4>Forgot Password ? </h4>
                            
                                    <div>
                                        
                                        <div className="container">
                                            <h3 className="gusetLog">Enter a valid email address.</h3>
                                            <Form.Group controlId="formBasicEmail"> 
                                              <Form.Control type="email" placeholder="Enter email" name="RePass" onChange={(Reemail) => this.setState({Reemail : Reemail.target.value})} /> 
                                          </Form.Group>
                                        </div>
                                    </div>
                                 
                            </div>
                            <div class="modal-footer"> 
                                
                                <a  class="btn btn-primary" onClick={() => {
                                        this.setState(
                                            {
                                            isPageLoad: true, 
                                            },
                                            () => {
                                            this.Reset();
                                            }
                                        );
                                        }}>
                               Submit
                                </a>

                                <a class="btn btn-secondary " data-dismiss="modal" onClick={() => {
                                            window.location.reload();
                                        }}
                                        >
                                            Close
                                </a>
                            </div> 
                        </Modal>



                        {/*** Reset Code */}

                        <Modal  className="modal" show={this.state.isReset}  close={this.closeModalHandler}  >  
                          <div className="Filters Filterxs"> 
                            <h4>Forgot Password ? </h4> 
                                    <div>
                                        
                                        <div className="container">
                                            <h3 className="gusetLog">Enter code</h3>
                                            <Form.Group controlId="formBasicEmail"> 
                                              <Form.Control type="text" placeholder="Enter Code" name="code" onChange={(code) => this.setState({CheckCode : code.target.value})} /> 
                                          </Form.Group>
                                        </div>
                                    </div>
                                 
                            </div>
                            <div class="modal-footer"> 
                                
                                <a  class="btn btn-primary" onClick={()=> this.ResetPass()} >
                                  Submit
                                </a>

                                <a class="btn btn-secondary " data-dismiss="modal" onClick={() => {
                                            window.location.reload();
                                        }}
                                        >
                                            Close
                                </a>

                            </div> 
                        </Modal>


                         {/*** Reset Password */}

                         <Modal  className="modal" show={this.state.RePassword}  close={this.closeModalHandler}  >  
                          <div className="Filters Filterxs"> 
                            <h4>Forgot password?</h4> 
                                    <div>
                                        
                                        <div className="container">
                                            <h3 className="gusetLog">Enter New Password</h3>
                                            <Form.Group controlId="formBasicEmail"> 
                                              <Form.Control type="password" placeholder="New Password" name="newPassword" onChange={(newPassword) => this.setState({newPassword : newPassword.target.value})} /> 
                                            </Form.Group>

                                            <Form.Group controlId="formBasicEmail"> 
                                                <Form.Control type="password" placeholder="Confirm New Password" name="newPasswordconf" onChange={(newPasswordconf) => this.setState({newPasswordconf : newPasswordconf.target.value})} /> 
                                            </Form.Group>
                                        </div>
                                    </div>
                                 
                            </div>
                            <div class="modal-footer"> 
                                
                                <a  class="btn btn-primary" onClick={()=> this.ResetPassConfirmation()} >
                                  Submit
                                </a>

                                <a class="btn btn-secondary " data-dismiss="modal" onClick={() => {
                                            window.location.reload();
                                        }}
                                        >
                                            Close
                                </a>

                            </div> 
                        </Modal>


                    </Row>
                </Container>
             </div>
         </div>    
         );
        }
    }
}

export default Login