import React, { Component } from "react";
import { Container, Row, Col, Modal, Form } from 'react-bootstrap';
import Slider from 'react-rangeslider'
import Global from '../Global.json';
// To include the default styles
import 'react-rangeslider/lib/index.css'

export class ProductDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product_id: props.match.params.product_id,
            cat_name: props.match.params.cat_name,
            user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
            guest_id: localStorage.getItem("guest_id") ? localStorage.getItem("guest_id") : 0,
            guest_mode: localStorage.getItem("guest_mode") ? localStorage.getItem("guest_mode") : false,
            currentValue: 10,
            value: 1,
            Rvalue: 1,
            isShowing: false,
            details: [],
            Questions: [],
            Options: [],
            Cat_id: 0,
            CatName: "",
            QuesOpt: [],
            isPageLoad: true,
            LoginQuest: false,
            Continue : false,
            Cart : false
        }

    }

    handleChangeStart = () => {
        console.log('Change event started')
    };

    handleChange = Rvalue => {
        this.setState({
            Rvalue: Rvalue
        })
    };

    handleChangeComplete = () => {
        console.log('Change event completed')
    };

    componentWillMount() {
         
        this.GetCatID()
        if (this.state.guest_id == 0) {
            //console.log(this.state.guest_id);
            fetch(Global.api_link + "generateGuestID", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: Global.token
                })
            })
                .then(response => response.json())
                .then(result => {
                    // console.log(result);
                    localStorage.setItem("guest_id", result.data);
                });
        }
    }

    GetCatID() {
        fetch(Global.api_link + 'getCatID', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token,
                cat_name: this.state.cat_name,
                lang: 'english'
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                    this.setState({
                        Cat_id: result.data.id,
                        CatName: result.data.name,
                        isPageLoad: false
                    })
                    this.getProductDetails()
                    this.Questions()
                }

            );
    }

    getProductDetails() {
        fetch(Global.api_link + 'getProductDetails', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token,
                product_id: this.state.product_id,
                lang: 'english'
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                    this.setState({
                        details: result.data
                    })
                }
            );
    }
    
    Questions() {
        fetch(Global.api_link + 'getQuestions', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token,
                cat_id: this.state.Cat_id,
                lang: 'english'
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                    this.setState({
                        Questions: result.data
                    })
                }
            );
    }

    decrease = () => {
        if (this.state.value !== 1) {
            this.setState({ value: this.state.value - 1 });
        }
    }

    increase = () => {
        this.setState({ value: this.state.value + 1 });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
        window.location.reload();
    }



    CheckCakeCart() {
        var date = new Date().getHours(); 
        if(date >= 8 && date <= 21 || this.state.details.type == 3) {
        fetch(Global.api_link + 'CheckCakeCart', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token,
                user_id: this.state.user_id !== 0 ? this.state.user_id : this.state.guest_id,
                cat_type:this.state.details.type
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                
                        if(this.state.user_id != 0 || this.state.guest_mode ) {
                           this.setState({ isShowing: true }) 
                        }else{
                             localStorage.setItem("guest_mode", true);
                            this.setState({ LoginQuest: true, guest_mode: false });
                        } 
                    }
            ) 
            }
        else {
            alert("Sorry! We can't proceed on your order now , We receive orders from 8 am to 9 pm")
        }
    }
 
    addProductToCart() {

        fetch(Global.api_link + 'addProductToCart', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: Global.token,
                product_id: this.state.product_id,
                user_id: this.state.user_id !== 0 ? this.state.user_id : this.state.guest_id,
                persons: this.state.Rvalue != 1 ? this.state.Rvalue : 1,
                qty: this.state.value,
                cat_type: this.state.details.type,
                comment: this.state.Comment ? this.state.Comment : " ",
                options: Object.values(this.state.QuesOpt).join(","),
            }),
        }).then((response) => response.json())
            .then(
                (result) => {
                    if (result.status == 3) {
                        alert("Blocked")
                    } else {
                        if(this.state.Continue == true ) {
                            window.location.reload()
                        }
                        else if(this.state.Cart == true){ 
                        window.location.href = "/Cart"
                        }
                    }
                }
            )
    }

 
    handleOptions = (selectedOption) => {
        let arr = []
        this.setState({
            selectedOption,
        });
        selectedOption.map((item) => {
            arr.push(item.value);
        })
        this.setState({ services: arr })
    }

    descriptionProduct(text) {
        return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
    }

    createMarkup(text) {
        return { __html: text };
    }

    redirect() {
        window.location.href = "/Login"
    }


    handleBack = () => {
        this.props.history.goBack()
      }

    render() {
        const { Rvalue } = this.state
        if (this.state.isPageLoad) {

            return <div>
                <div className="Loader">
                    <img alt="JaDough" src="/img/giphy.gif" />
                </div>
            </div>;
        } else {
            return (
                <div>
                    <div className="BodyImage">
                        <Container>
                            <Row className="DessertsDet"> {/** Desserts */}
                                <Col md="4" sm="4" xs="12" className="MobileImage">
                                    <img alt="JaDough" src={Global.image_link + this.state.details.image} />
                                </Col>
                                <Col md="6" sm="6" xs="12">
                                    <h3>{this.state.details.name}</h3>
                                    <p>
                                        {this.descriptionProduct(this.state.details.desc)}
                                    </p>
                                    <div className="PriceRange">
                                        <h3>{this.state.details.price ? "QR" +this.state.details.price  : null}</h3>
                                        {this.state.details.price ? 
                                        <div className="def-number-input number-input QntMob">
                                            <button onClick={this.decrease} className="minus"></button>
                                            <input className="quantity" name="quantity" value={this.state.value} onChange={() => console.log('change')}
                                                type="number" />
                                            <button onClick={this.increase} className="plus"></button>
                                        </div>
                                        :null}
                                    </div>
                                    {this.state.details.type == "110" ?
                                        <Col md="8" sm="8" xs="12">
                                            <div>
                                                <div className="slider">
                                                    <Slider
                                                        min={0}
                                                        max={100}
                                                        value={Rvalue}
                                                        tooltip={false}
                                                        onChangeStart={this.handleChangeStart}
                                                        onChange={this.handleChange}
                                                        onChangeComplete={this.handleChangeComplete}
                                                    />
                                                    <                       div className='value'>{Rvalue} Presons</div>
                                                </div>
                                            </div>
                                        </Col>
                                        : null
                                    }

                                    {this.state.cat_name != 'cupcakes' && this.state.cat_name != 'cookies' && this.state.cat_name != 'cake_pops'? 
                                    <Col md="8" sm="8" xs="12" className="OrderNOwContainer">
                                         <a className="OrderNOwBack" onClick={() =>
                                           this.handleBack() 
                                        }>Back</a>
                                        
                                        <a className="OrderNOw" onClick={() =>
                                           this.CheckCakeCart() 
                                        }>Order Now</a>  
                                       
                                    </Col>
                                    : 
                                     <Col md="8" sm="8" xs="12" className="OrderNOwContainer">
                                        <a className="OrderNOw" href ="/Catering/2">Customize</a>
                                    </Col>
                                    }
    
                                     
                                   
                                </Col>
                                <Col md="2" sm="2" xs="12">
                                </Col>

                                <Col md="4" sm="4" xs="12" className="WebImage">
                                    <img alt="JaDough" src={Global.image_link + this.state.details.image} />
                                </Col>


                                <Modal className="modal" show={this.state.LoginQuest} close={this.closeModalHandler}  >
                                    <div className="Filters Filterxs">
                                        <h4>Login </h4>

                                        <div>
                                            <div className="container">
                                                <h3 className="gusetLog">Do you want to order as a guest ?</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <a class="btn btn-secondary " data-dismiss="modal" onClick={() => {
                                            this.setState({ isShowing: true })
                                        }}
                                        >
                                            Continue
                                        </a>
                                        <a class="btn btn-primary" onClick={() => this.redirect()} >
                                            Login first
                                        </a>
                                    </div>
                                </Modal>





                                <Modal className="modal" show={this.state.isShowing} close={this.closeModalHandler}  >
                                    <div className="Filters Filterxs">
                                        <h4>Add Item Choices </h4>
                                        {this.state.Questions.map(Ques => {
                                            return (
                                                <div>
                                                    <h5>{Ques.question_name } </h5>
                                                    <div className="container">
                                                        <div className="RadioForm">

                                                            {Ques.answers ?
                                                                Ques.answers.map(option => {
                                                                    return (
                                                                        <label class="containerRadio">{option.answer_name }  {option.answer_op_price != 0 ? " + QR" + option.answer_op_price : null}  
                                                                            <input type="radio" name={"radio" + Ques.question_id} value={option.answer_name} onChange={(radio) => { this.AddRemoveOptionsArray(Ques.question_id, radio.target.value ); }} /> 
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    )
                                                                })
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        <div>
                                            <h5>Special requests</h5>
                                            <Form.Control as="textarea" rows="3" placeholder="Eg. If you have a food allergy" name="comment" onChange={(comment) => this.setState({ Comment: comment.target.value })} />
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <a class="btn btn-secondary " data-dismiss="modal" onClick={() => {
                                            window.location.reload();
                                            }}
                                        >
                                            Close
                                        </a>
                                        
                                        <a class="btn btn-primary" onClick={() => {
                                            this.setState(
                                                {
                                                    Continue: true, 
                                                },
                                                () => {
                                                this.addProductToCart();
                                                }
                                            );
                                            }}>
                                                Add To Cart
                                        </a>
                                    </div>
                                </Modal>
                            </Row>
                        </Container>
                    </div>
                </div>
            );
        }
    }
    AddRemoveOptionsArray(qid, answer) {
        let array = this.state.QuesOpt;
        array['op' + qid] = answer;

        this.setState({ QuesOpt: array });
        console.log(Object.values(this.state.QuesOpt).join(","));
    }

}
export default ProductDetails;

